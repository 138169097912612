import { onMounted, onUnmounted } from 'vue'

export function useBackNavigation(callback: () => void) {
    const handlePopState = () => {
        callback()
    }

    onMounted(() => {
        window.addEventListener('popstate', handlePopState)
    })

    onUnmounted(() => {
        window.removeEventListener('popstate', handlePopState)
    })
}
